import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Itinerary from './components/Itinerary';
import GettingStarted from './components/GettingStarted';
import AdultsChildren from './components/AdultsChildren';
import CheckInCheckOut from './components/CheckInCheckOut';
import SelectResort from './components/SelectResort';
import SelectRoom from './components/SelectRoom';
import ReviewAndSave from './components/ReviewAndSave';
import Login from './components/Login';
import About from './components/About';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Logout from './components/Logout';
import SubscriptionPage from './components/SubscriptionPage';

import './App.css';

const App = () => {
  return (
    <Routes>
      {/* Login Route */}
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />

      {/* Public Routes */}
      <Route path="/about" element={<About />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/subscription" element={<SubscriptionPage />} />

      {/* Itinerary Routes */}
      <Route path="/itinerary/:itinerary_id" element={<Itinerary />} />
      <Route path="/itinerary" element={<Itinerary />} />

      {/* Getting Started and other flow routes */}
      <Route path="/getting-started" element={<GettingStarted />} />
      <Route path="/adults-children" element={<AdultsChildren />} />
      <Route path="/check-in-check-out" element={<CheckInCheckOut />} />
      <Route path="/select-resort" element={<SelectResort />} />
      <Route path="/select-room" element={<SelectRoom />} />
      <Route path="/review-and-save" element={<ReviewAndSave />} />

      {/* Redirect root path to /itinerary */}
      <Route path="/" element={<Navigate to="/itinerary" />} />
    </Routes>
  );
};

export default App;
