import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ReviewAndSave.css';
import { dbFQDN } from '../config';

const ReviewAndSave = () => {
  const navigate = useNavigate();
  const storedUser = localStorage.getItem('user');
  const user = JSON.parse(storedUser);

  // State for the new inputs
  const [itineraryName, setItineraryName] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const email = user?.email;
  //const adults = localStorage.getItem('adults');
  //const children = localStorage.getItem('children');
  //const childrenAges = localStorage.getItem('children_ages');
  const checkIn = localStorage.getItem('check_in');
  const checkOut = localStorage.getItem('check_out');
  const itinerary_type = localStorage.getItem('selection');
  //const selectedResort = JSON.parse(localStorage.getItem('selected_resort'));
  //const selectedRoom = JSON.parse(localStorage.getItem('selected_room'));
  //const parkDays = parseInt(localStorage.getItem('parkDays'), 10) || 4;  // Default to 4 park days

  const handleSave = () => {
    // Check if the itinerary name is provided
    if (!itineraryName) {
      alert('Please provide a name for your itinerary.');
      return; // Stop the function if the name is not provided
    }

    function convertToUTC(dateStr, timeStr) {
      const localTime = new Date(`${dateStr}T${timeStr}:00`);
      const timestamp = localTime.getTime();
      const utcTime = new Date(timestamp);
      return utcTime.toISOString().split('.')[0] + 'Z';
    }

    const checkInStartTimeUTC = convertToUTC(checkIn, "15:00");  // 3:00 PM CT
    const checkInEndTimeUTC = convertToUTC(checkOut, "23:45");   // 11:45 PM CT

    const itineraryData = {
      itinerary_type: itinerary_type,
      owner: email,
      ta_id: null,
      startTime: checkInStartTimeUTC,
      endTime: checkInEndTimeUTC,
      private: isPrivate,
      name: itineraryName
    };

    console.log('Itinerary Data:', itineraryData);

    fetch(`${dbFQDN}/di/v1/itineraries`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(itineraryData)
    })
    .then(response => response.json())
    .then(itinerary => {
      const checkInStartTimeUTC = convertToUTC(checkIn, "15:00"); // 3:00 PM CT
      const checkInEndTimeUTC = convertToUTC(checkIn, "23:45");   // 11:45 PM CT
      const checkOutStartTimeUTC = convertToUTC(checkOut, "11:00"); // 11:00 AM CT
      const checkOutEndTimeUTC = convertToUTC(checkOut, "23:45");  // 11:45 PM CT

      const resortItems = [
        {
          itinerary_id: itinerary.id,
          parent_id: "resort",
          child_id: "Disneys_Pop_Century_Resort",
          comment: "✅🏨 Disney's Pop Century Resort -- Check-In",
          startTime: checkInStartTimeUTC,
          endTime: checkInEndTimeUTC
        },
        {
          itinerary_id: itinerary.id,
          parent_id: "resort",
          child_id: "Disneys_Pop_Century_Resort",
          comment: "😭 Disney's Pop Century Resort -- Check-Out",
          startTime: checkOutStartTimeUTC,
          endTime: checkOutEndTimeUTC
        }
      ];

      fetch(`${dbFQDN}/di/v1/itineraries/${itinerary.id}/items`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(resortItems) // Sending the array of items as the payload
      });

      navigate(`/itinerary/${itinerary.id}`);
    });
  };

  const formatDateString = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="review-and-save-container">
      <div className="top-navigation" style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#0078ae', color: 'white', fontSize: '14px' }}>
        <div className="back-arrow" style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => window.history.back()}>
          <i className="fas fa-arrow-left"></i> Back
        </div>
        <div className="home-button" style={{ cursor: 'pointer', flexGrow: 1, textAlign: 'center' }} onClick={() => window.location.href = '/'}>
          <i className="fas fa-home"></i> Exit
        </div>
      </div>

      <h1 style={{ color: '#0078ae', marginBottom: '20px' }}>Review Your Itinerary</h1>

      {/* Styled Section for Itinerary Details */}
      <div className="itinerary-details">
        {/* Itinerary Name */}
        <div className="itinerary-row">
          <div className="itinerary-column">
            <label htmlFor="itinerary-name" className="itinerary-label">Itinerary Name:</label>
            <input
              type="text"
              id="itinerary-name"
              value={itineraryName}
              onChange={(e) => setItineraryName(e.target.value)}
              placeholder="Enter a name"
              className="itinerary-input"
              required
            />
          </div>
        </div>

        {/* Check-In and Check-Out Dates */}
        <div className="itinerary-row">
          <div className="itinerary-column">
            <label className="date-label">Check-in:</label>
            <span className="date-value">{formatDateString(checkIn)}</span>
            <span className="date-separator">|</span>
            <label className="date-label">Check-out:</label>
            <span className="date-value">{formatDateString(checkOut)}</span>
          </div>
        </div>

        {/* Publicly Accessible Toggle */}
        <div className="itinerary-row">
          <div className="itinerary-column">
            <label className="itinerary-label">Publicly Accessible:</label>
            <button className="lock-button" onClick={() => setIsPrivate(!isPrivate)}>
              <i className={isPrivate ? 'fas fa-lock' : 'fas fa-unlock'}></i> {isPrivate ? 'No' : 'Yes'}
            </button>
          </div>
        </div>
      </div>

      <button onClick={handleSave} style={{ maginTop: "20px", backgroundColor: '#0078ae', color: 'white', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', fontSize: '16px' }}>
        Save
      </button>
    </div>
  );
};

export default ReviewAndSave;
