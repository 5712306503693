import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Login.css'; // Assuming you have a separate CSS file for styling
import { dbFQDN } from '../config';

const clientId = '857486561791-0kioc9813l6kotvv8hrdq7vcp9n6l75g.apps.googleusercontent.com';

const Login = () => {
  const navigate = useNavigate();

  const onSuccess = (credentialResponse) => {

    if (!credentialResponse.credential) {
      console.error('ID Token not found in the response');
      return;
    }

    fetch(`${dbFQDN}/auth/google/callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: credentialResponse.credential }),
      credentials: 'include'  // Ensure cookies are included in the request
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('user', JSON.stringify(data.user));
      Cookies.set('token', data.access_token, { expires: 30, secure: true, sameSite: 'None', domain: '.ditinerary.com' });  // Ensure the domain attribute is set
      navigate('/itinerary'); // Redirect to the itinerary page
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const onError = () => {
    console.log('Login Failed');
    alert('Failed to login.');
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="login-container">
        <div className="login-content">
          <h1 className="login-title">Welcome to Ditinerary</h1>
          <p className="login-subtitle">Please log in using one of the methods below to access your itineraries.</p>
          <div className="login-button">
            <GoogleLogin
              onSuccess={onSuccess}
              onError={onError}
            />
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
