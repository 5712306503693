import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to access URL parameters
import { format, eachDayOfInterval, isToday } from 'date-fns';
import SwipeableViews from 'react-swipeable-views';
import './Itinerary.css';
import { FiMenu } from 'react-icons/fi';
import '@fortawesome/fontawesome-free/css/all.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes } from 'date-fns';
import { appName, siteFQDN, dbFQDN, basePath, defaultBackgroundColor, customerPortal } from '../config';
import { Link } from 'react-router-dom';
import  generateNewItineraryItems  from './generateItineraryItems';

const Itinerary = () => {
  const { itinerary_id } = useParams(); // Access itinerary_id from the URL
  const [user, setUser] = useState(null);
  const [headers, setHeaders] = useState({});
  const [items, setItems] = useState([]);
  const [editable, setEditable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditItineraryModal, setShowEditItineraryModal] = useState(false);
  const [accessList, setAccessList] = useState([]);
  const [selectedItineraryName, setSelectedItineraryName] = useState('');
  const [selectedStartTime, setSelectedStartTime] = useState('');
  const [selectedEndTime, setSelectedEndTime] = useState('');
  const [showAgentModal, setShowAgentModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showMyItinerariesModal, setShowMyItinerariesModal] = useState(false);
  const [showSharedItinerariesModal, setShowSharedItinerariesModal] = useState(false);
  const [latestItinerary, setLatestItinerary] = useState(null);
  const [itineraries, setItineraries] = useState(null);
  const [sharedItineraries, setSharedItineraries] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedItem, setExpandedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [comment, setComment] = useState('');
  const [selectedItinerary, setSelectedItinerary] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [selectedChildId, setSelectedChildId] = useState(null);
  const [showSelectItem, setShowSelectItem] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false); // State to toggle dropdown visibility
  const [selectedTime, setSelectedTime] = useState(setHours(setMinutes(new Date(), 0), 12));
  const [itemsData, setItemsData] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const initialLoad = useRef(true); // Ref to track if it's the first load

  const category_data = [
    { id: 'park', name: 'Park', icon: 'fas fa-chess-rook' },
    { id: 'ride', name: 'Rides', icon: 'fas fa-rocket' },
    { id: 'dining', name: 'Dining', icon: 'fas fa-utensils' },
    { id: 'travel', name: 'Travel', icon: 'fas fa-bus' },
    { id: 'extra', name: 'Extras', icon: 'fas fa-star' },
    { id: 'note', name: 'Notes', icon: 'fas fa-sticky-note' }
  ];

  useEffect(() => {
    const token = localStorage.getItem('token');
    let headers = {};
  
    if (token) {
      headers = {
        'Authorization': `Bearer ${token}`
      };
      setHeaders(headers);}

    if (itinerary_id) {
      if (itinerary_id !== "OGERPVM") {
        // Fetch the specific itinerary if itinerary_id is provided
        fetch(`${dbFQDN}/di/v1/itineraries/${itinerary_id}`, {
          headers: headers
        })
          .then(response => response.json())
          .then(data => {
            // Set the searched itinerary
            setLatestItinerary(data.searched_itinerary);

            setEditable(data?.user_itineraries?.some(itinerary => itinerary.id === itinerary_id) || false);

            // Set the user's itineraries
            setItineraries(data.user_itineraries);

            // Set the Shared itineraries
            setSharedItineraries(data.shared_itineraries);

            // Apply the itinerary items returned from the API
            const itemsData = data.itinerary_items;
            setItems(itemsData);

            // Handle initial load: Set the current index based on today's date
            if (initialLoad.current && itemsData) {
              const activeDayIndex = itemsData.findIndex(item => isToday(new Date(item.startTime)));
              setCurrentIndex(activeDayIndex !== -1 ? activeDayIndex : 0);
              initialLoad.current = false;
            }
            
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
              try {
                const parsedUser = JSON.parse(storedUser);
                setUser(parsedUser); 
              } catch (error) {
                setUser({
                  email: "user@ditinerary.com",
                  name: "Unknown",
                  picture: "https://ditinerary.com/assets/images/profile.webp",
                });
              }
            } else {
              setUser({
                email: "user@ditinerary.com",
                name: "Unknown",
                picture: "https://ditinerary.com/assets/images/profile.webp",
              });
            }
          });
      } else {
        setUser({
          email: "user@ditinerary.com",
          name: "Unknown",
          picture: "https://ditinerary.com/assets/images/profile.webp",
        });
        const data = {
          id: "OGERPVM",
          startTime: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000), // Adds 1 day
          endTime: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000) // Adds 5 days
        };
        // Set the user's itineraries
        setItineraries(data);

        // Set the Shared itineraries
        setSharedItineraries(data);

        setLatestItinerary(data);
        const newItineraryItems = generateNewItineraryItems(1);
        setItems(newItineraryItems);
      }
    } else {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        try {
          const parsedUser = JSON.parse(storedUser);
          setUser(parsedUser);

          fetch(`${dbFQDN}/di/v1/itineraries`, {
            headers: headers
          })
            .then(response => response.json())
            .then(data => {
              // Set the searched itinerary
              setLatestItinerary(data.searched_itinerary);

              setEditable(data?.searched_itinerary?.id !== "OGERVPM");
          
              // Set the user's itineraries
              setItineraries(data.user_itineraries);

              // Set the Shared itineraries
              setSharedItineraries(data.shared_itineraries);
          
              // Apply the itinerary items returned from the API
              const itemsData = data.itinerary_items;
              setItems(itemsData);
          
              // Handle initial load: Set the current index based on today's date
              if (initialLoad.current) {
                const activeDayIndex = itemsData.findIndex(item => isToday(new Date(item.startTime)));
                setCurrentIndex(activeDayIndex !== -1 ? activeDayIndex : 0);
                initialLoad.current = false;
              }
            });  
        } catch (error) {
          const data = {
            id: "OGERPVM",
            startTime: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000), // Adds 1 day
            endTime: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000) // Adds 5 days
          };
          // Set the user's itineraries
          setItineraries(data);
  
          // Set the Shared itineraries
          setSharedItineraries(data);
  
          setLatestItinerary(data);
          const newItineraryItems = generateNewItineraryItems(1);
          setItems(newItineraryItems);
        }
      } else {
        setUser({
          email: "user@ditinerary.com",
          name: "Unknown",
          picture: "https://ditinerary.com/assets/images/profile.webp",
        });
        const data = {
          id: "OGERPVM",
          startTime: new Date(Date.now() + 1 * 24 * 60 * 60 * 1000), // Adds 1 day
          endTime: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000) // Adds 5 days
        };
        // Set the user's itineraries
        setItineraries(data);

        // Set the Shared itineraries
        setSharedItineraries(data);

        setLatestItinerary(data);
        const newItineraryItems = generateNewItineraryItems(1);
        setItems(newItineraryItems);
      }
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [itinerary_id]); // Removed expandedItem from dependencies
  
  const generateBackgroundImage = () => {
    if (!selectedParentId) return defaultBackgroundColor;

    const itemPart = selectedChildId ? `${selectedChildId}` : 'preview';
    const imageUrl = `/c/${basePath}/${selectedParentId}/${itemPart}.webp`;

    return imageUrl;
  };

  const backgroundImageStyle = {
    backgroundImage: `url(${generateBackgroundImage()})`,
    backgroundPosition: 'center',
    backgroundColor: selectedParentId && !selectedChildId ? defaultBackgroundColor : 'none',
  };

  const handleEditItem = (item) => {
    setSelectedItemId(item.id);  
    setSelectedParentId(item.parent_id);  
    setSelectedChildId(item.child_id);          
    setComment(item.comment);                 
    setSelectedTime(new Date(item.startTime)); 
    setShowModal(true);                       
  };

  const handleAddItemClick = (date) => {          
    const newDate = new Date(date);
    newDate.setHours(12, 0, 0, 0); // Set to 12:00:00 PM

    setSelectedItemId(null);  
    setSelectedParentId(null);  
    setSelectedChildId(null);     
    setComment(null);   
    setSelectedTime(newDate);
    setShowModal(true);  
  };

  const toggleAgentModal = () => {
    setShowAgentModal(!showAgentModal);
  };

  const toggleProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  const toggleAccountModal = () => {
    setShowAccountModal(!showAccountModal);
  };

  const toggleMyItinerariesModal = () => {
    setSearchTerm('');
    setShowMyItinerariesModal(!showMyItinerariesModal);
  };
  
  const toggleSharedItinerariesModal = () => {
    setShowSharedItinerariesModal(!showSharedItinerariesModal);
  };
  
  const toggleEditItineraryModal = () => {
    setSearchTerm('');
    setShowEditItineraryModal(!showEditItineraryModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const toggleExpand = (id) => {
    setExpandedItem(prevExpandedItem => prevExpandedItem === id ? null : id);
  };

  const handleAddPerson = () => {
    const emailInput = document.getElementById('email');
    const email = emailInput.value.trim();
    
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!email) {
      alert("Please enter an email address.");
    } else if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
    } else if ((accessList || []).includes(email)) {
      alert("This email is already in the access list.");
    } else {
      setAccessList([...(accessList || []), email]);
      emailInput.value = ''; // Clear the input field after adding
    }
  };
  
  const handleRemovePerson = (email) => {
    setAccessList(accessList.filter(item => item !== email));
  };

  const copyToClipboard = (itineraryId = null) => {
    const idToUse = itineraryId || latestItinerary.id; // Use itineraryId if provided, otherwise use latestItinerary.id
    const url = `${siteFQDN}/itinerary/${idToUse}`;
    
    navigator.clipboard.writeText(url)
      .then(() => {
        alert('URL copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const handleMessageSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
  
    // Get form values
    const name = document.getElementById('name').value.trim();
    const email = document.getElementById('email').value.trim();
    const message = document.getElementById('message').value.trim();
  
    // Validate the form data (you can add more validation if needed)
    if (!name || !email || !message) {
      alert('Please fill in all fields.');
      return;
    }
  
    // Create the data object to send
    const data = {
      name,
      email,
      message,
    };
  
    // Send the data to the server using fetch
    fetch(`${dbFQDN}/di/v1/contact-ta`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          alert('Your message has been sent successfully!');
          toggleAgentModal(); // Close the modal after successful submission
        } else {
          alert('There was an issue sending your message. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('An error occurred while sending your message. Please try again.');
      });
  };  

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
  
    // Process the form submission here, such as updating the itinerary with the new data
    const updatedItinerary = {
      ...selectedItinerary,
      name: selectedItineraryName,
      ta_id: typeof accessList === 'string' ? accessList : JSON.stringify(accessList),  // Only stringify if not already a string
      owner: user.email
    };
    
  
    const startDate = format(selectedStartTime, 'yyyy-MM-dd');
    const startTime = format(selectedStartTime, 'HH:mm');
  
    const endDate = format(selectedEndTime, 'yyyy-MM-dd');
    const endTime = format(selectedEndTime, 'HH:mm');
  
    // Convert startTime and endTime to UTC
    function convertToUTC(dateStr, timeStr) {
      const localTime = new Date(`${dateStr}T${timeStr}:00`);
      const timestamp = localTime.getTime();
      const utcTime = new Date(timestamp);
      return utcTime.toISOString().split('.')[0] + 'Z';
    }
  
    updatedItinerary.startTime = convertToUTC(startDate, startTime);
    updatedItinerary.endTime = convertToUTC(endDate, endTime);
  
    console.log(updatedItinerary);
  
    if (user.email !== "user@ditinerary.com") {
      fetch(`${dbFQDN}/di/v1/itineraries/${latestItinerary.id}`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedItinerary)
      })
        .then(response => response.json())
        .then(savedItinerary => {
          savedItinerary.ta_id = JSON.parse(savedItinerary.ta_id);  // Parse ta_id back to an array
          setItineraries(prevItineraries =>
            prevItineraries.map(itinerary =>
              itinerary.id === savedItinerary.id ? savedItinerary : itinerary
            )
          );
        });
    } else {
      // Manually update the itinerary when the user is not logged in
      const updatedItineraryWithTempId = {
        ...updatedItinerary,
        id: selectedItinerary.id || `temp-${Date.now()}`, // Create a temporary ID if none exists
      };
      updatedItineraryWithTempId.ta_id = JSON.parse(updatedItineraryWithTempId.ta_id);  // Parse ta_id back to an array
      setItineraries(prevItineraries =>
        prevItineraries.map(itinerary =>
          itinerary.id === updatedItineraryWithTempId.id ? updatedItineraryWithTempId : itinerary
        )
      );
    }

    setSelectedItinerary(null);
    setSelectedItineraryName(null);
    setAccessList(null)
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    toggleEditItineraryModal();
  };  
  

  const handleSaveItem = (event) => {
    event.preventDefault();

    let selectedDate = "";

    if (!isMobile) {
      selectedDate = format(selectedTime, 'yyyy-MM-dd');
    } else {
      selectedDate = format(new Date(days[currentIndex]), 'yyyy-MM-dd');
    }

    const startTime = format(selectedTime, 'HH:mm');

    // Convert startTime and endTime to UTC
    function convertToUTC(dateStr, timeStr) {
      // Create a Date object in the specified time zone using the provided date and time
      const localTime = new Date(`${dateStr}T${timeStr}:00`);
      
      // Get the Unix timestamp for the local time in milliseconds
      const timestamp = localTime.getTime();
      
      // Create a new Date object based on the Unix timestamp
      const utcTime = new Date(timestamp);
      
      // Convert to UTC string and return it
      return utcTime.toISOString().split('.')[0] + 'Z';
  }

    const startTimeUTC = convertToUTC(selectedDate, startTime);
    const endTimeUTC = convertToUTC(selectedDate, '23:45');

    if (selectedParentId === "note") {
      setSelectedChildId("eton");
    }

    const newItem = {
      parent_id: selectedParentId,
      child_id: selectedChildId,
      comment: comment,
      startTime: startTimeUTC,
      endTime: endTimeUTC,
      itinerary_id: latestItinerary.id
    };

    const apiUrl = selectedItemId 
      ? `${dbFQDN}/di/v1/itineraries/${latestItinerary.id}/items/${selectedItemId}` 
      : `${dbFQDN}/di/v1/itineraries/${latestItinerary.id}/items`;
  
    const method = selectedItemId ? 'PUT' : 'POST';
  
    if (user.email !== "user@ditinerary.com") {
      fetch(apiUrl, {
        method: method,
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newItem)
      })
        .then(response => response.json())
        .then(savedItem => {
          if (method === 'POST') {
            setItems([...items, savedItem]);
          } else {
            setItems(items.map(item => item.id === selectedItemId ? savedItem : item));
          }
        });
    } else {
      // Manually create the savedItem when the user is not logged in
      const savedItem = {
        ...newItem,
        id: selectedItemId || `temp-${Date.now()}`, // Create a temporary ID if none exists
      };

      if (method === 'POST') {
        setItems([...items, savedItem]);
      } else {
        setItems(items.map(item => item.id === selectedItemId ? savedItem : item));
      }
    }
    setExpandedItem(prevExpandedItem => prevExpandedItem === selectedItemId ? null : selectedItemId);
    setShowModal(false);
  };  
  

  function getDaysArray(start, end) {
    return eachDayOfInterval({
      start: new Date(start),
      end: new Date(end),
    });
  }

  // Function to convert UTC time to Central Time
  function convertUTCToCentral(utcDate) {
    // Create a Date object from the UTC time
    const date = new Date(utcDate);
  
    // Convert to Central Time
    const options = {
      timeZone: 'America/Chicago', 
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric'
    };
    const centralTime = date.toLocaleString('en-US', options);
  
    // Return the new Date object for filtering and sorting
    return new Date(centralTime);
  }

  // Function to convert UTC date to local datetime format for datetime-local input
  function convertToLocalDateTimeString(utcDateString) {
    const date = new Date(utcDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const initializeAccessList = (itinerary) => {
    try {
      if (itinerary.ta_id) {
        // Attempt to parse ta_id if it exists
        const parsedTaId = JSON.parse(itinerary.ta_id);
        setAccessList(Array.isArray(parsedTaId) ? parsedTaId : []);
      } else {
        // If ta_id is null or undefined, set an empty array
        setAccessList([]);
      }
    } catch (error) {
      // If JSON parsing fails, also set an empty array or handle the error accordingly
      console.error("Failed to parse ta_id:", error);
      setAccessList([]);
    }
  };

  const editItinerary = (editingItinerary) => {
    setSelectedItinerary(editingItinerary);
    setSelectedItineraryName(editingItinerary.name);
    initializeAccessList(editingItinerary);
    setSelectedStartTime(convertToLocalDateTimeString(convertUTCToCentral(editingItinerary.startTime)));
    setSelectedEndTime(convertToLocalDateTimeString(convertUTCToCentral(editingItinerary.endTime)));
    toggleEditItineraryModal();
  };

  const toggleLock = (toggledItinerary) => {
    const newStart = convertToLocalDateTimeString(convertUTCToCentral(toggledItinerary.startTime));
    const newEnd = convertToLocalDateTimeString(convertUTCToCentral(toggledItinerary.endTime));

    // Process the form submission here, such as updating the itinerary with the new data
    const updatedItinerary = {
      ...toggledItinerary,
      name: toggledItinerary.name,
      ta_id: typeof toggledItinerary.ta_id === 'string' ? toggledItinerary.ta_id : JSON.stringify(toggledItinerary.ta_id),  // Only stringify if not already a string
      private: !toggledItinerary.private,
      owner: user.email
    };    
  
    const startDate = format(newStart, 'yyyy-MM-dd');
    const startTime = format(newStart, 'HH:mm');
  
    const endDate = format(newEnd, 'yyyy-MM-dd');
    const endTime = format(newEnd, 'HH:mm');
  
    // Convert startTime and endTime to UTC
    function convertToUTC(dateStr, timeStr) {
      const localTime = new Date(`${dateStr}T${timeStr}:00`);
      const timestamp = localTime.getTime();
      const utcTime = new Date(timestamp);
      return utcTime.toISOString().split('.')[0] + 'Z';
    }
  
    updatedItinerary.startTime = convertToUTC(startDate, startTime);
    updatedItinerary.endTime = convertToUTC(endDate, endTime);
  
    if (user.email !== "user@ditinerary.com") {
      fetch(`${dbFQDN}/di/v1/itineraries/${toggledItinerary.id}`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedItinerary)
      })
        .then(response => response.json())
        .then(savedItinerary => {
          savedItinerary.ta_id = JSON.parse(savedItinerary.ta_id);  // Parse ta_id back to an array
          setItineraries(prevItineraries =>
            prevItineraries.map(itinerary =>
              itinerary.id === savedItinerary.id ? savedItinerary : itinerary
            )
          );
        });
    } else {
      // Manually update the itinerary when the user is not logged in
      const updatedItineraryWithTempId = {
        ...updatedItinerary,
        id: selectedItinerary.id || `temp-${Date.now()}`, // Create a temporary ID if none exists
      };
      updatedItineraryWithTempId.ta_id = JSON.parse(updatedItineraryWithTempId.ta_id);  // Parse ta_id back to an array
      setItineraries(prevItineraries =>
        prevItineraries.map(itinerary =>
          itinerary.id === updatedItineraryWithTempId.id ? updatedItineraryWithTempId : itinerary
        )
      );
    }
  };

  const centralStartTime = latestItinerary ? convertUTCToCentral(latestItinerary.startTime) : null;
  const centralEndTime = latestItinerary ? convertUTCToCentral(latestItinerary.endTime) : null;
  const days = (centralStartTime && centralEndTime) ? getDaysArray(new Date(centralStartTime), new Date(centralEndTime)) : [];  

  const handleDayClick = (index) => {
    setCurrentIndex(index);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };

  const updateActiveTab = (index) => {
    setCurrentIndex(index);
    const tabs = document.querySelectorAll('.tab');
    tabs.forEach((tab, i) => {
      if (i === index) {
        tab.classList.add('active');
      } else {
        tab.classList.remove('active');
      }
    });
  };

  const renderItineraryCards = () => {
    //if (!latestItinerary) return null;

    if (!isMobile) {
        // Render a standard scrollable container for desktop
        return (
          <div className="desktop-container">
              <div className="desktop-top-bar">
                <div className="top-bar-row top-row">
                    <div className="top-bar-left">
                        <div className="logo-name">
                            <span className="app-name">{appName}</span>
                        </div>
                    </div>
                    <div className="top-bar-right">
                    <div 
                        className="user-greeting" 
                        onClick={user.email !== "user@ditinerary.com" ? toggleDropdown : null}
                      >
                        {user.email !== "user@ditinerary.com" ? (
                          <>
                            Hello, {user.name} <i className="fas fa-caret-down"></i>
                          </>
                        ) : (
                          <button 
                            onClick={() => window.location.href = '/login'} 
                            className="login-button"
                          >
                            Log In
                          </button>
                        )}
                      </div>
                      {showDropdown && (
                        <div className="dropdown-menu">
                          <button onClick={() => window.location.href = '/logout'} className="logout-button">
                            Log Out
                          </button>
                        </div>
                      )}
                    </div>
                </div>
                <div className="top-bar-row bottom-row">
                    <div className="top-bar-buttons">
                        <button onClick={toggleMyItinerariesModal}><i className="fas fa-calendar-alt"></i> My Itineraries</button>
                        <button onClick={toggleSharedItinerariesModal}><i className="fas fa-tasks"></i> Shared Itineraries</button>
                        <button onClick={toggleProfileModal}><i className="fas fa-user"></i> My Profile</button>
                        <button onClick={toggleAgentModal} className="talk-to-agent-button">
                          <i className="fas fa-star"></i> Talk to a Travel Agent 
                          <span className="badge-free">Free</span>
                        </button>
                    </div>
                </div>
              </div>
              <div style={{ display: 'flex', overflowX: 'auto', marginTop: '10px', maxHeight: '626px' }}>
                {days.length > 0 ? (
                  latestItinerary.id === "OGERPVM" && user.email !== "user@ditinerary.com" ? (
                    <div className="getting-started" style={{ minWidth: '626px', minHeight: '626px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'white' }}>
                      <div style={{ textAlign: 'center' }}>
                        <img src="/assets/images/ropedrop.webp" alt="No items available" width="363" height="363" />
                        
                        {/* Adding a break and some spacing between the image and the button */}
                        <br />
                        
                        <button
                          onClick={() => window.location.href = '/getting-started'}
                          style={{
                            backgroundColor: 'green',
                            color: 'white',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            marginTop: '20px'  // Adds spacing between the image and button
                          }}
                        >
                          Click to create your first Itinerary!
                        </button>
                      </div>
                    </div>
                  ) : (
                    days.map((day, index) => {
                        const canEdit = editable || latestItinerary.id === "OGERPVM" || sharedItineraries.some(itinerary => itinerary.id === latestItinerary.id);

                        // Filter and sort items based on central Time Zone
                        const dayItems = items
                          .filter(item => {
                            const itemDate = convertUTCToCentral(item.startTime).toDateString();
                            return itemDate === day.toDateString();
                          })
                          .sort((a, b) => convertUTCToCentral(a.startTime) - convertUTCToCentral(b.startTime));

                        return (
                            <div key={index} className="desktop-card" style={{ minWidth: '320px', marginRight: '10px' }}>
                                <div className="add-item-bar">
                                    <div className="mobile-full-date">
                                        {format(day, 'EEEE M/d')}
                                    </div>
                                    <button
                                        className="add-item-button"
                                        onClick={() => handleAddItemClick(day)}
                                        disabled={!canEdit} // Disable the button if the itinerary can't be edited
                                        style={{ marginRight: '5px', cursor: canEdit ? 'pointer' : 'not-allowed' }}
                                    >
                                        <i className="fas fa-plus"></i> Add Item
                                    </button>
                                </div>
                                <div className="schedule-container">
                                    {dayItems.map((item, idx) => (
                                        <div key={idx} className="time-slot" style={{ backgroundImage: `url(/c/${basePath}/${item.parent_id}/${item.child_id || 'default'}.webp)`
                                        , backgroundPosition: 'center' }}>
                                            <div className="time-slot-content">
                                                <div className="time-box">{format(new Date(item.startTime), 'h:mm a').toUpperCase()}</div>
                                                <div className="meatball-menu" onClick={(e) => {
                                                    e.stopPropagation(); // Prevent event bubbling
                                                    e.preventDefault();  // Prevent any default behavior
                                                    toggleExpand(item.id);
                                                }}>&#x2022;&#x2022;&#x2022;</div>
                                            </div>
                                            <div className="caption-bar">
                                                <div className="caption">{item.comment}</div>
                                                {expandedItem === item.id && (
                                                    <div className="expanded-section">
                                                        <div className="trash-can" onClick={() => handleDeleteItem(item.id)}>&#128465;</div>
                                                        <div className="edit-button" onClick={() => handleEditItem(item)}>&#9998;</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })
                  )
                ) : (
                  user.email !== "user@ditinerary.com" ? (
                    <>
                      <div className="loading-modal">
                        <div className="modal-content-loading">
                          <img src="/assets/images/logo.png" alt="Loading logo" className="loading-logo" />
                          <div className="loading-text">Loading...</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    // Render the fallback image if days is empty and the user email matches
                    <>
                      <div className="getting-started" style={{ minWidth: '626px', minHeight: '626px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'white' }}>
                        <div style={{ textAlign: 'center' }}>
                          <img src="/assets/images/401.webp" alt="No items available" width="363" height="363" />
                        </div>
                      </div>
                    </>
                  )
                )              
                }
              </div>
              <div className="desktop-bottom-bar">
                <div className="bottom-bar-row first-row">
                  <Link to="/about">About DItinerary</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                  <Link to="/terms-and-conditions">Terms and Conditions</Link>
                  <a className="coffee-button" href="https://buymeacoffee.com/ditinerary" target="_blank" rel="noopener noreferrer">
                      <i className="fas fa-coffee"></i> Buy me a coffee
                  </a>
                </div>
                <div className="bottom-bar-row second-row">
                  <a className="social-icon" href="https://www.linkedin.com/in/enriqueramon"><i className="fab fa-linkedin"></i></a>
                  <a className="social-icon" href="https://www.facebook.com/enrique.destinationmagic"><i className="fab fa-facebook"></i></a>
                  <a className="social-icon" href="https://www.instagram.com/enrique.destinationmagic"><i className="fab fa-instagram"></i></a>
                  <a className="social-icon" href="https://www.tiktok.com/@waltsentme"><i className="fab fa-tiktok"></i></a>
                </div>
              </div>
          </div>
      );
    } else {
        // Render SwipeableViews for mobile
        return (
          <>
            {days.length > 0 ? (
              latestItinerary.id === "OGERPVM" && user.email !== "user@ditinerary.com" ? (
                  <div className="getting-started" style={{ minWidth: '626px', minHeight: '626px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', backgroundColor: 'white' }}>
                    <div style={{ textAlign: 'center' }}>
                      <img src="/assets/images/ropedrop.webp" alt="No items available" width="363" height="363" />
                      
                      {/* Adding a break and some spacing between the image and the button */}
                      <br />
                      
                      <button
                        onClick={() => window.location.href = '/getting-started'}
                        style={{
                          backgroundColor: 'green',
                          color: 'white',
                          padding: '10px 20px',
                          border: 'none',
                          borderRadius: '5px',
                          cursor: 'pointer',
                          fontSize: '16px',
                          marginTop: '20px'  // Adds spacing between the image and button
                        }}
                      >
                        Click to create your first Itinerary!
                      </button>
                    </div>
                  </div>
              ) : (
                  <SwipeableViews
                      className="swipeable-views-container"
                      slideClassName="swipeable-views-slide"
                      index={currentIndex}
                      onChangeIndex={(index) => updateActiveTab(index)}
                      enableMouseEvents
                      resistance
                      style={{ width: '100%' }}
                      containerStyle={{ width: 'auto' }}
                  >
                      {days.map((day, index) => {
                        // Filter and sort items based on central Time Zone
                        const dayItems = items
                          .filter(item => {
                            const itemDate = convertUTCToCentral(item.startTime).toDateString();
                            return itemDate === day.toDateString();
                          })
                          .sort((a, b) => convertUTCToCentral(a.startTime) - convertUTCToCentral(b.startTime));
          
                          return (
                              <div key={index} className="swipeable-views-slide">
                                  <div className="card-top">
                                      <div className="full-date">
                                          {format(day, 'EEEE M/d')}
                                      </div>
                                  </div>
                                  <div className="schedule-container">
                                      {dayItems.map((item, idx) => (
                                          <div key={idx} className="time-slot" style={{ backgroundImage: `url(/c/${basePath}/${item.parent_id}/${item.child_id || 'default'}.webp)`
                                          , backgroundPosition: 'center' }}>
                                              <div className="time-slot-content">
                                                  <div className="time-box">{format(new Date(item.startTime), 'hh:mm a').toUpperCase()}</div>
                                                  <div className="meatball-menu" onClick={(e) => {
                                                      e.stopPropagation(); // Prevent event bubbling
                                                      e.preventDefault();  // Prevent any default behavior
                                                      toggleExpand(item.id);
                                                  }}>&#x2022;&#x2022;&#x2022;</div>
                                              </div>
                                              <div className="caption-bar">
                                                  <div className="caption">{item.comment}</div>
                                                  {expandedItem === item.id && (
                                                      <div className="expanded-section">
                                                          <div className="trash-can" onClick={() => handleDeleteItem(item.id)}>&#128465;</div>
                                                          <div className="edit-button" onClick={() => handleEditItem(item)}>&#9998;</div>
                                                      </div>
                                                  )}
                                              </div>
                                          </div>
                                      ))}
                                  </div>
                              </div>
                          );
                      })}
                  </SwipeableViews>
              )
            ) : (
              <div className="getting-started" style={{ minHeight: '626px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                <div style={{ textAlign: 'center' }}>
                  <img src="/assets/images/ropedrop.webp" alt="No items available" width="363" height="363" />
                  
                  {/* Adding a break and some spacing between the image and the button */}
                  <br />
                  
                  <button
                    onClick={() => window.location.href = '/getting-started'}
                    style={{
                      backgroundColor: 'green',
                      color: 'white',
                      padding: '10px 20px',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      fontSize: '16px',
                      marginTop: '20px'  // Adds spacing between the image and button
                    }}
                  >
                    Click to create your first Itinerary!
                  </button>
                </div>
              </div>
            )}
          </>
        );        
    }
};


  const handleDeleteItem = (itemId) => {
    if (user.email !== "user@ditinerary.com") {
      fetch(`${dbFQDN}/di/v1/itineraries/${latestItinerary.id}/items/${itemId}`, {
        method: 'DELETE',
        headers: headers
      })
        .then(response => {
          if (response.status === 204) {
            setItems(items.filter(item => item.id !== itemId));
          } else {
            console.error('Failed to delete the item');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      setItems(items.filter(item => item.id !== itemId));
    }
  };

  const renderTopBar = () => (
    <div className="top-bar">
      {days.map((day, index) => (
        <div
          key={index}
          className={`tab ${index === currentIndex ? 'active' : ''}`}
          onClick={() => handleDayClick(index)}
        >
          {format(day, 'EEE M/d')}
        </div>
      ))}
    </div>
  );

  if (!user) {
    return <p>Loading...</p>;
  }

  const handleCategorySelect = (parentId) => {
    setSelectedParentId(parentId);
  
    if (parentId === 'note') {
      setShowSelectItem(false);
    } else {
      setShowSelectItem(true);
  
      // Check if data for the selected parentId already exists in itemsData
      if (itemsData[parentId]) {
        return; // Exit the function early if data is already available
      }
  
      // If data doesn't exist, proceed with the fetch
      fetch(`${dbFQDN}/di/v1/${parentId}`)
      .then(response => response.json())
      .then(data => {
        const transformedData = data.map(item => {
          let keywords = '';
          try {
            // Directly access the keywords since `item.data` is already an object
            const parsedData = item.data || {};
            keywords = parsedData.keywords ? parsedData.keywords.join(', ') : '';
          } catch (error) {
            console.error('Error processing item data:', error);
          }
          return {
            ...item,
            keywords
          };
        });
        setItemsData(prevItemsData => ({ ...prevItemsData, [parentId]: transformedData }));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };
  

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    if (value && selectedParentId) {
      const results = itemsData[selectedParentId]?.filter(item =>
        item.keywords.toLowerCase().includes(value)
      );
      setFilteredResults(results || []);
    } else {
      setFilteredResults([]);
    }
  };

  const handleItemSelect = (item) => {
    setSelectedChildId(item.id);
    setSearchTerm('');
    setFilteredResults([]);
    setComment(item.name);
  };

  const filteredItineraries = Array.isArray(itineraries) ? itineraries.filter(itinerary =>
    (itinerary.name && itinerary.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (itinerary.startTime && new Date(itinerary.startTime).toLocaleDateString().includes(searchTerm))
  ) : [];  

  const filteredSharedItineraries = Array.isArray(sharedItineraries) ? sharedItineraries.filter(itinerary =>
    (itinerary.name && itinerary.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (itinerary.startTime && new Date(itinerary.startTime).toLocaleDateString().includes(searchTerm))
  ) : [];

  return (
    <>
      {user.email === "user@ditinerary.com" && (
        <div className="demo-mode-bar">
          Demo Mode -- Please Sign in for best experience.
        </div>
      )}
      {renderTopBar()}
      <div className="itinerary-container">
        {renderItineraryCards()}
        <div className="bottom-bar">
          <button className="mobile-my-itineraries" onClick={toggleMyItinerariesModal}><i className="fas fa-calendar-alt"></i></button>
          <button
            className="mobile-add-item-button"
            onClick={() => setShowModal(true)}
            disabled={days.length === 0}
          >
            +
          </button>
          <button className="hamburger-icon" onClick={toggleAccountModal}><FiMenu color="#fff" size={24} /></button>
        </div>
      </div>
      {showAgentModal && (
        <div className="modal message-ta">
          <div className="modal-content">
            <h2 className="modal-title"><i className="fas fa-star"></i> Talk to a Travel Agent 
            <span className="badge-free">Free</span>
            </h2>

            <form className="agent-form">
              <div className="input-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" placeholder="Your Name" required />
              </div>

              <div className="input-group">
                <label htmlFor="email">E-Mail:</label>
                <input type="email" id="email" name="email" placeholder="Your E-Mail" required />
              </div>

              <div className="input-group">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" placeholder="Your Message" rows="4" required></textarea>
              </div>

              <div className="modal-buttons">
                <button onClick={toggleAgentModal} className="modal-close-button">Close</button>
                <button type="submit" onClick={handleMessageSubmit} className="modal-green-button">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showEditItineraryModal && (
        <div className="modal edit-itinerary">
          <div className="modal-content">
            <h2 className="modal-title">Editing {selectedItineraryName}:</h2>
            {/* Form for editing itinerary access and times */}
            <form className="edit-itinerary-form">
              {/* Editable Itinerary Name */}
              <div className="input-group">
                <label htmlFor="itinerary-name">Itinerary Name: </label>
                <input 
                  type="text" 
                  id="itinerary-name" 
                  name="itineraryName" 
                  value={selectedItineraryName} 
                  onChange={(e) => setSelectedItineraryName(e.target.value)} 
                  placeholder="Enter itinerary name" 
                  required 
                  style={{ padding: '8px', borderRadius: '5px', border: '1px solid #ccc', width: '100%' }}
                />
              </div>

              {/* Email input for adding a person */}
              <div className="input-group">
                <label htmlFor="email">Add e-mail to grant access:</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    placeholder="Enter email address" 
                    required 
                    style={{ flexGrow: 1, marginRight: '10px' }}
                  />
                  <button type="button" onClick={handleAddPerson} className="modal-plus-button">+</button>
                </div>
              </div>

              {/* List of people with access */}
              <div className="access-list">
                <h3>E-Mails with Access:</h3>
                {(accessList || []).map((email, index) => (
                  <div key={index} className="access-row">
                    <span>{email}</span>
                    <button type="button" onClick={() => handleRemovePerson(email)} className="modal-x-button">x</button>
                  </div>
                ))}
              </div>

              {/* Input for Start Time (Check-In) */}
              <div className="input-group">
                <label htmlFor="startTime">Check-In:</label>
                <input 
                  type="datetime-local" 
                  id="startTime" 
                  name="startTime" 
                  value={selectedStartTime} 
                  onChange={(e) => setSelectedStartTime(e.target.value)} 
                  required 
                />
              </div>

              {/* Input for End Time (Check-Out) */}
              <div className="input-group">
                <label htmlFor="endTime">Check-Out:</label>
                <input 
                  type="datetime-local" 
                  id="endTime" 
                  name="endTime" 
                  value={selectedEndTime} 
                  onChange={(e) => setSelectedEndTime(e.target.value)} 
                  required 
                />
              </div>

              {/* Modal buttons */}
              <div className="modal-buttons">
                <button onClick={toggleEditItineraryModal} className="modal-close-button">Close</button>
                <button type="submit" onClick={handleSubmit} className="modal-green-button">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showProfileModal && (
        <div className="modal">
          <div className="modal-content">
          {user ? (
              user.email !== "user@ditinerary.com" ? (
                <>
                  <h2 className="itinerary-header"><i className="fas fa-user"></i> {user.name}'s Profile</h2>
                  
                  {/* Email Field */}
                  <div>
                    <label htmlFor="email">Email: </label><b>{user.email}</b>
                  </div>

                  {/* Subscription Plan */}
                  <div>
                    <label htmlFor="subscription">Subscription Plan: </label><b>{user && user.di_ta ? "Professional" : "Free"}</b>
                  </div>
                    {user && user.di_ta ? (
                      <button onClick={() => window.location.href = customerPortal} className="upgrade-button">
                        Customer Portal
                      </button>
                    ) : (
                      <>
                        <button onClick={() => window.location.href = '/subscription'} className="upgrade-button">
                          Upgrade to Professional
                        </button>
                        <button onClick={toggleProfileModal}className="close-button">
                          Close
                        </button>
                      </>
                    )}
                </>
              ) : (
                <>
                  <h2 class="login-header">Log in to Save and Share</h2>
                  <p class="login-description">
                      Register for a free account to be able to save and share itineraries. Professional and Business accounts are available for upgraded features. Login and visit "My Subscriptions" for more details.
                  </p>
                  <div class="login-buttons">
                      <button onClick={toggleProfileModal}className="close-button">
                          Close
                      </button>
                      <button onClick={() => window.location.href = '/login'} className="login-button">
                          Log In
                      </button>
                  </div>
                </>
              )
            ) : (
              <>
                <h2 class="login-header">Log in to Save and Share</h2>
                <p class="login-description">
                    Register for a free account to be able to save and share itineraries. Professional and Business accounts are available for upgraded features. Login and visit "My Subscriptions" for more details.
                </p>
                <div class="login-buttons">
                      <button onClick={toggleProfileModal}className="close-button">
                          Close
                      </button>
                      <button onClick={() => window.location.href = '/subscription'} className="upgrade-button">
                        Subscription Pricing
                      </button>
                  </div>
              </>
            )}
          </div>
        </div>
      )}
      {showAccountModal && (
        <div className="modal">
          <div className="modal-content">
            {/* Legal Links at the Top */}
            <div className="legal-links">
              <Link to="/about">About DItinerary</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </div>

            {user ? (
              user.email !== "user@ditinerary.com" ? (
                <>
                  <h2 class="margin-three">{user.name}'s Subscription</h2>
                  <button onClick={() => window.location.href = '/logout'} className="logout-mobile-button margin-three">Log Out</button>
                  
                  {/* Email Field */}
                  <div className="Subscription-field">
                    <label htmlFor="email">Email: </label><b>{user.email}</b>
                  </div>

                  {/* Subscription Plan */}
                  <div className="subscription-field">
                    <label htmlFor="subscription">Subscription Plan: </label><b>{user && user.di_ta ? "Professional" : "Free"}</b>
                  </div>

                  {user && user.di_ta ? (
                    <button onClick={() => window.location.href = customerPortal} className="mobile-modal-button center-me">
                      Customer Portal
                    </button>
                  ) : (
                    <>
                      <button onClick={() => window.location.href = '/subscription'} className="mobile-modal-button center-me">
                        Upgrade to Professional
                      </button>
                      <a className="mobile-modal-button center-me remove-underline" href="https://buymeacoffee.com/ditinerary" target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-coffee"></i> Buy me a coffee
                      </a>
                    </>
                  )}

                  {/* Action Buttons at the Bottom */}
                  <div className="action-buttons center-me">
                    <button onClick={toggleAgentModal} className="mobile-modal-button">
                      <i className="fas fa-star"></i> Talk to a Travel Agent 
                      <span className="badge-free">Free</span>
                    </button>
                    <button onClick={toggleSharedItinerariesModal} className="mobile-modal-button"><i className="fas fa-tasks"></i> Shared Itineraries</button>
                    <button onClick={toggleMyItinerariesModal} className="mobile-modal-button"><i className="fas fa-calendar-alt"></i> My Itineraries</button>
                  </div>
                </>
              ) : (
                <>
                  <h2>Log in to Save and Share</h2>
                  <p>
                    Register for a free account to be able to save and share itineraries. Professional and Business accounts are available for upgraded features. Login and visit "My Subscriptions" for more details.
                  </p>
                  <button onClick={() => window.location.href = '/login'} className="signup-button">
                    Log In
                  </button>
                </>
              )
            ) : (
              <>
                <p>Please log in to view your Subscription.</p>
                <button onClick={() => window.location.href = '/subscription'} className="upgrade-button">
                  Subscription Pricing
                </button>
              </>
            )}
            <button class="modal-close-button" onClick={toggleAccountModal}>Close</button>
          </div>
        </div>
      )}
      {showSharedItinerariesModal && (
        <div className="modal">
          <div className="modal-content">
            <>
              <h2 className="itinerary-header"><i className="fas fa-tasks"></i> Shared Itineraries:</h2>
              {/* Explanation Section */}
              <div className="explanation-section">
                <div className="explanation-item">
                  <span>Set access to public <i className="fas fa-unlock unlock-color"></i> or private <i className="fas fa-lock lock-color"></i> -- Users will need to log in for private itineraries.</span>
                </div>
                <div className="explanation-item">
                  <i className="fas fa-cog cog-icon"></i>
                  <span>Edit itinerary settings. For example: Name, Times, and E-Mails to grant access to private itineraries.</span>
                </div>
                <div className="explanation-item">
                  <i className="fas fa-copy copy-icon"></i>
                  <span>Copies the itinerary URL to the clipboard for easy sharing.</span>
                </div>
              </div>

              {/* Search Input (Only displays if there are 10 or more itineraries) */}
              {sharedItineraries && sharedItineraries.length >= 10 && (
                <div className="search-section">
                  <input 
                    type="text" 
                    placeholder="Search itineraries..." 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                    className="search-input"
                  />
                </div>
              )}

              {filteredSharedItineraries && filteredSharedItineraries.length > 0 ? (
                <div className="itinerary-table">
                  {filteredSharedItineraries.map(itinerary => (
                    <div className="itinerary-row" key={itinerary.id}>
                      <div className="itinerary-column">
                        <div className="itinerary-name">{itinerary.name}</div>
                        <div className="itinerary-dates">
                          <span className="date-label">In:</span> <span className="date-value">{new Date(itinerary.startTime).toLocaleDateString()}</span>
                          {isMobile ? (
                            <>
                              <br />
                              <span className="date-label">Out:</span> <span className="date-value">{new Date(itinerary.endTime).toLocaleDateString()}</span>
                            </>
                          ) : (
                            <>
                              <span className="date-separator">|</span>
                              <span className="date-label">Out:</span> <span className="date-value">{new Date(itinerary.endTime).toLocaleDateString()}</span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="itinerary-column">
                        <button className="lock-button" onClick={() => toggleLock(itinerary)}>
                          <i className={itinerary.private ? 'fas fa-lock' : 'fas fa-unlock'}></i>
                        </button>
                      </div>
                      <div className="button-group">
                        <button className="cog-button" onClick={() => editItinerary(itinerary)}><i className="fas fa-cog"></i></button>
                        <button className="copy-button" onClick={() => copyToClipboard(itinerary.id)}>
                          <i className="fas fa-copy"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="login-description">No Shared Itineraries available.</p> // Fallback message when itineraries is empty or null
              )}

              <div className="modal-buttons">
                <button onClick={toggleSharedItinerariesModal} className="modal-close-button">
                  Close
                </button>
              </div>
            </>
          </div>
        </div>
      )}
      {showMyItinerariesModal && (
        <div className="modal">
          <div className="modal-content">
            <>
              <h2 className="itinerary-header"><i className="fas fa-calendar-alt"></i> My Itineraries:</h2>

              {/* Explanation Section */}
              <div className="explanation-section">
                <div className="explanation-item">
                  <span>Set access to public <i className="fas fa-unlock unlock-color"></i> or private <i className="fas fa-lock lock-color"></i> -- Users will need to log in for private itineraries.</span>
                </div>
                <div className="explanation-item">
                  <i className="fas fa-cog cog-icon"></i>
                  <span>Edit itinerary settings. For example: Name, Times, and E-Mails to grant access to private itineraries.</span>
                </div>
                <div className="explanation-item">
                  <i className="fas fa-copy copy-icon"></i>
                  <span>Copies the itinerary URL to the clipboard for easy sharing.</span>
                </div>
              </div>

              {/* Search Input (Only displays if there are 10 or more itineraries) */}
              {itineraries && itineraries.length >= 10 && (
                <div className="search-section">
                  <input 
                    type="text" 
                    placeholder="Search itineraries..." 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                    className="search-input"
                  />
                </div>
              )}

              {filteredItineraries && filteredItineraries.length > 0 ? (
                <div className="itinerary-table">
                  {/* Table Rows */}
                  {filteredItineraries.map(itinerary => (
                    <div className="itinerary-row" key={itinerary.id}>
                      <div className="itinerary-column">
                        <div className="itinerary-name">{itinerary.name}</div>
                        <div className="itinerary-dates">
                          
                          {isMobile ? (
                            <>
                              <span className="date-label"><b className="gray-date">Check-In: </b>{new Date(itinerary.startTime).toLocaleDateString()}</span>
                              <span className="date-label"><b className="gray-date">Check-Out: </b>{new Date(itinerary.endTime).toLocaleDateString()}</span>
                            </>
                          ) : (
                            <>
                              <span className="date-label">In:</span> <span className="date-value">{new Date(itinerary.startTime).toLocaleDateString()}</span>
                              <span className="date-separator">|</span>
                              <span className="date-label">Out:</span> <span className="date-value">{new Date(itinerary.endTime).toLocaleDateString()}</span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="itinerary-column">
                        <button className="lock-button" onClick={() => toggleLock(itinerary)}>
                          <i className={itinerary.private ? 'fas fa-lock' : 'fas fa-unlock'}></i>
                        </button>
                      </div>
                      <div className="button-group">
                        <button className="cog-button" onClick={() => editItinerary(itinerary)}><i className="fas fa-cog"></i></button>
                        <button className="copy-button" onClick={() => copyToClipboard(itinerary.id)}>
                          <i className="fas fa-copy"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="login-description">No Itineraries available.</p> // Fallback message when itineraries is empty or null
              )}

              <div className="modal-buttons">
                <button onClick={toggleMyItinerariesModal} className="modal-close-button">
                  Close
                </button>
                <button onClick={() => window.location.href = '/getting-started'} className="modal-green-button">
                  Create New Itinerary
                </button>
              </div>
            </>
          </div>
        </div>
      )}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            {/* Modal preview */}
            <div className="modal-preview">
              <div className="schedule-container-preview">
                <div className="time-slot" style={backgroundImageStyle}>
                  <div className="time-slot-content">
                    <div className="time-box">
                      {selectedTime ? selectedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '12:00 PM'}
                    </div>
                    <div className="meatball-menu">&#x2022;&#x2022;&#x2022;</div>
                  </div>
                  <div className="caption-bar">
                    <div className="caption">{comment}</div>
                  </div>
                </div>
              </div>
              <div className="preview-label">Preview</div>
            </div>

            {/* Modal for selecting category and items */}
            <div className="modal-select-category">
              <h2>Select a Category:</h2>
              <div className="category-buttons">
                {category_data.map((category, index) => (
                  <div
                    key={index}
                    className={`category-button-wrapper ${selectedParentId === category.id ? 'selected' : ''}`}
                    onClick={() => handleCategorySelect(category.id)}
                  >
                    <button className="category-button">
                      <i className={category.icon}></i>
                    </button>
                    <div className="category-name">{category.name}</div>
                  </div>
                ))}
              </div>
            </div>

            {showSelectItem && (
              <div className="modal-select-item">
                <h2>Select Itinerary Item:</h2>
                <input
                  type="text"
                  placeholder="Search for an item..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="search-bar"
                />
                {filteredResults.length > 0 && (
                  <div className="search-results">
                    {filteredResults.map((item, index) => (
                      <div
                        key={index}
                        className="search-result-item"
                        onClick={() => handleItemSelect(item)}
                      >
                        {item.name}
                        <span className="plus-icon">+</span>
                      </div>
                    ))}
                  </div>
                )}
                {selectedChildId && (
                  <div className="selected-indicator">
                    Selected: {itemsData[selectedParentId]?.find(item => item.id === selectedChildId)?.name}
                  </div>
                )}
              </div>
            )}

            {/* Comment section */}
            <div className="modal-comment">
              <label>Comment:</label>
              <textarea
                placeholder="Enter a comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>

            <div className="modal-time">
              <label>Start Time:</label>
              <div className="time-picker-container">
                <DatePicker
                  selected={selectedTime}
                  onChange={time => setSelectedTime(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  className="time-picker-input"
                />
              </div>
            </div>

            <div className="modal-actions">
              <button type="button" onClick={handleCloseModal}>CLOSE</button>
              <button type="submit" onClick={handleSaveItem}>SAVE</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Itinerary;